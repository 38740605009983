$(function() {
    function getPopoverContent(popoverLink, commodityId) {
        var $url = Routing.generate('web.product.popover', {"commodity": commodityId});
        var response = '';
        if(popoverLink.attr("data-toggle") == "popover"){
            return $('#popover-content-'+commodityId).html();
        }
        else{
            response = $.ajax({
                url: $url,
                dataType: 'html',
                async: false
            }).done(function (data) {
                popoverLink.attr("data-toggle", "popover");
                return data;
            });
            $(popoverLink).parent().append('<div class="popover-content hidden" id="popover-content-'+commodityId+'">' +
                response.responseText +'</div>');
            return response.responseText;
        }

    }

    $(document).ready(function() {
        $('.commodity-popover').each(function(e) {
            var $popoverLink = $(this);
            var $popoverTitle = $(this).html();
            var $commodityId = $popoverLink.attr('data-popover-commodity-id');
            var popoverPlacement = 'auto';

            if ($popoverLink.attr('data-popover-placement')) {
                popoverPlacement = $popoverLink.attr('data-popover-placement');
            }

            $popoverLink.popover({
                delay: {"show": 100, "hide": 100},
                trigger: 'hover',
                title: $popoverTitle,
                content: function(){
                    return getPopoverContent($popoverLink, $commodityId);
                },
                placement: popoverPlacement,
                html: true
            });
        });
    });
});