/**
 * Useful JS utils library (e.g. load modules via namespaces)
 *
 * @author    Christopher Christen <c.christen@tenolo.de>
 * @copyright Copyright c 2018, tenolo GbR
 */
tenoloUtils = {

    VERSION: '0.0.1',

    /* ERRORS
     ---------------------------------------------------------- */

    /**
     * Throws an error with the given message.
     *
     * @param msg
     */
    error: function (msg) {
        throw new Error(msg);
    },

    /* TYPES
     ---------------------------------------------------------- */

    type: {

        /**
         * Determine whether the given value is undefined.
         *
         * @param val
         *
         * @returns {boolean}
         */
        isUndefined: function (val) {
            return typeof val === 'undefined';
        },

        /**
         * Determine whether the given value is of the type string.
         *
         * @param val
         *
         * @returns {boolean}
         */
        isString: function (val) {
            return typeof val === 'string' || val instanceof String;
        },

        /**
         * Determine whether the given value is of the type array.
         *
         * @param val
         *
         * @returns {boolean}
         */
        isArray: function (val) {
            return Object.prototype.toString.call(val) === '[object Array]';
        },

        /**
         * Determine whether the given value is a function.
         *
         * @param val
         *
         * @returns {boolean}
         */
        isFunction: function (val) {
            var getType = {};
            return o && getType.toString.call(o) === '[object Function]';
        },

        /**
         * Determine whether the given value is an object.
         *
         * @param val
         *
         * @returns {boolean}
         */
        isObject: function (val) {
            return val === Object(val);
        }
    },

    /* STRING
     ---------------------------------------------------------- */

    string: {

        /**
         * Determine whether the given string ends with the given suffix.
         *
         * @param str
         * @param suffix
         *
         * @returns {boolean}
         */
        endsWith: function (str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }
    },

    /* NAMESPACE
     ---------------------------------------------------------- */

    namespace: {

        /**
         * Register the namespace with the given name.
         *
         * @param namespace
         * @param object
         * @param overwriteProperties
         */
        register: function (namespace, object, overwriteProperties) {
            if (!tenoloUtils.type.isString(namespace)) {
                tenoloUtils.error('Parameter "{namespace}" must be of type string');
            }

            if (!tenoloUtils.type.isObject(object)) {
                tenoloUtils.error('Parameter "{object}" must be an object');
            }

            if (tenoloUtils.type.isUndefined(overwriteProperties)) {
                overwriteProperties = false;
            }

            var namespaceComponents = namespace.split('.');

            // Check all namespace components. (> 0)
            for (var i in namespaceComponents) {
                if (namespaceComponents[i].length === 0) {
                    tenoloUtils.error('Namespace "{' + namespace + '}" contains empty namespace components');
                }
            }

            var lastNamespaceObj = window;
            var lastNamespaceName = '';

            // Create namespace components
            // @todo refactor massive for loop
            for (var i in namespaceComponents) {

                // Create a new namespace if it doesn't already exist
                if (tenoloUtils.type.isUndefined(lastNamespaceObj[namespaceComponents[i]])) {
                    // Create a new namespace object
                    lastNamespaceObj[namespaceComponents[i]] = {};
                    // Remember as last used namespace object
                    lastNamespaceObj = lastNamespaceObj[namespaceComponents[i]];
                    // Remember as last used namespace name
                    lastNamespaceName += (lastNamespaceName === '' ? '' : '.') + namespaceComponents[i];

                    // Transfer properties from the transferred namespace object to the newly created namespace object
                    if (lastNamespaceName === namespace) {
                        this._copyProperties(object, lastNamespaceObj, overwriteProperties);
                    }
                }

                // Add to existing namespace
                else if (tenoloUtils.type.isObject(lastNamespaceObj[namespaceComponents[i]])) {
                    // Remember as last used namespace object
                    lastNamespaceObj = lastNamespaceObj[namespaceComponents[i]];
                    // Remember as last used namespace name
                    lastNamespaceName += (lastNamespaceName === '' ? '' : '.') + namespaceComponents[i];

                    // Transfer properties from the transferred namespace object to the newly created namespace object
                    if (lastNamespaceName === namespace) {
                        this._copyProperties(object, lastNamespaceObj, overwriteProperties);
                    }
                }

                // Throw error if no object exists
                else {
                    // Remember as last used namespace object
                    lastNamespaceObj = lastNamespaceObj[namespaceComponents[i]];
                    // Remember as last used namespace name
                    lastNamespaceName += (lastNamespaceName === '' ? '' : '.') + namespaceComponents[i];

                    tenoloUtils.error('Namespace "{' + lastNamespaceName + '}" can not be created; Already exists as a non-object variable');

                    return;
                }
            }
        },

        /**
         * Copy the properties of one object to another.
         *
         * @private
         *
         * @param objectFrom
         * @param objectTo
         * @param overwriteProperties
         */
        _copyProperties: function (objectFrom, objectTo, overwriteProperties) {
            for (var propertyName in objectFrom) {
                this._addPropertyToObject(objectTo, propertyName, objectFrom[propertyName], overwriteProperties);
            }
        },

        /**
         * Add the passed property to the passed object.
         *
         * @private
         *
         * @param object
         * @param propertyName
         * @param propertyValue
         * @param overwriteProperties
         */
        _addPropertyToObject: function (object, propertyName, propertyValue, overwriteProperties) {
            if (tenoloUtils.type.isUndefined(object[propertyName])) {
                object[propertyName] = propertyValue;
            } else {
                if (overwriteProperties) {
                    object[propertyName] = propertyValue;
                } else {
                    tenoloUtils.error('Property "{' + propertyName + '}" already exists and can not be overwritten');
                }
            }
        }
    },

    /* RESOURCES
     ---------------------------------------------------------- */

    resource: {

        /**
         * Basic path for the resources to be loaded.
         */
        basePath: '',

        /**
         * Hold the file names of already loaded resources.
         *
         * @private
         */
        _loadedResources: [],

        /**
         * Load the passed CSS or JS resource.
         *
         * @param filename
         */
        load: function (filename) {
            if (this._loadedResources.indexOf(filename) !== -1) {
                return;
            }

            var element;

            // @todo improve file loading (e.g. handle redundant backslashes)
            if (tenoloUtils.string.endsWith(filename, '.js')) {
                element = document.createElement('script');
                element.setAttribute('type', 'text/javascript');
                element.setAttribute('src', this.basePath + filename);
            }

            if (tenoloUtils.string.endsWith(filename, '.css')) {
                element = document.createElement('link');
                element.setAttribute('rel', 'stylesheet');
                element.setAttribute('type', 'text/css');
                element.setAttribute('href', this.basePath + filename);
            }

            if (!tenoloUtils.type.isUndefined(element)) {
                document.getElementsByTagName('head')[0].appendChild(element);
            }
        }
    }
};
