/**
 * Equalize heights of divs.
 *
 * @author Christopher Christen <c.christen@tenolo.de>
 * @copyright Copyright c 2018, tenolo GbR
 */
$.fn.equalHeights = function (breakpoint) {
    var $that = $(this);

    if (typeof breakpoint === "undefined") {
        breakpoint = 990;
    }

    var setDivHeight = function (windowWidth, breakpoint) {
        $that.css('height', '');

        if (windowWidth > breakpoint) {
            var tallest = 0;

            $that.each(function () {
                var height = $(this).height();

                if (height > tallest) {
                    tallest = height;
                }
            });

            $that.height(tallest);
        }
    };

    setDivHeight($(window).width(), breakpoint);

    $(window).resize(function () {
        setDivHeight($(window).width(), breakpoint);
    });

    return this;
};
