$(function () {
    if (Modernizr.touchevents) {
        $("a.dropdown-toggle")
            .on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();

                var $link = $(this);
                var $drowdown = $link.parent();

                if ($drowdown.hasClass('open')) {
                    location.href = $(this).attr('href');
                } else {
                    $drowdown.siblings().removeClass("open");
                    $drowdown.addClass('open');
                }

                return false;
            });

        // Close the dropdown if the user clicks outside of it
        $("body").on("click", function (event) {
            if (!event.target.matches('a.dropdown-toggle') && !event.target.matches('div.dropdown-menu *')) {
                $("a.dropdown-toggle").each(function () {
                    $(this).parent().removeClass("open");
                });
            }
        });
    }
});
