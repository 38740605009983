// require
const $ = require('jquery');
const bootbox = require('bootbox');

// symfony
const routes = require('../../public/js/fos_js_routes.json');
const Routing = require('../../public/bundles/fosjsrouting/js/router.min.js');

const imagesLoaded = require('imagesloaded');

// jquery ui - you have to require each module separate
// require('jquery-ui/ui/widgets/accordion');
// require('jquery-ui/ui/widgets/autocomplete');
// require('jquery-ui/ui/widgets/button');
// require('jquery-ui/ui/widgets/checkboxradio');
// require('jquery-ui/ui/widgets/controlgroup');
// require('jquery-ui/ui/widgets/datepicker');
// require('jquery-ui/ui/widgets/dialog');
// require('jquery-ui/ui/widgets/draggable');
// require('jquery-ui/ui/widgets/droppable');
// require('jquery-ui/ui/widgets/menu');
// require('jquery-ui/ui/widgets/mouse');
// require('jquery-ui/ui/widgets/progressbar');
// require('jquery-ui/ui/widgets/resizable');
// require('jquery-ui/ui/widgets/selectable');
// require('jquery-ui/ui/widgets/selectmenu');
require('jquery-ui/ui/widgets/slider');
// require('jquery-ui/ui/widgets/sortable');
// require('jquery-ui/ui/widgets/spinner');
// require('jquery-ui/ui/widgets/tabs');
// require('jquery-ui/ui/widgets/tooltip');

// // classic npm modules
require('bootstrap');
require('select2');
require('bootstrap-datepicker');
require('slick-carousel');
require('jquery-easy-loading');
require('bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox'); // has to full path
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.de'); // has to full path
//
// // custom stuff
require('../../public/bundles/tenolocore/js/jquery-extend.js');
require('../../public/bundles/tenolocore/js/utilities.js');
require('../../public/bundles/tenolobootstrap/js/bootstrap-collection.js');
require('../../public/bundles/tenolobootstrap/js/bootstrap-notify.js');
require('../../public/bundles/tenolobootstrap/js/bootstrap-utilites.js');
require('../../public/bundles/tenolobootstrap/js/bootstrap-modal.js');
require('../../public/bundles/tenolobootstrap/js/bootstrap-hover-dropdown.min.js');
require('../../public/bundles/tenolobootstrap/js/webshim/polyfiller.js');

// custom app stuff
require('../../public/bundles/rabeconceptshopweb/js/app.js');

// js for sim cards
require('./sim.js');


Routing.setRoutingData(routes);

// after crazy stuff
imagesLoaded.makeJQueryPlugin($);

// create globals
global.$ = global.jQuery = $;
global.bootbox = bootbox;
global.Routing = Routing;
global.imagesLoaded = imagesLoaded;
