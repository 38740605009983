$(function () {
    // link confirm dialog
    $('body').on('click', 'a.ajax-confirm', function (e) {
        e.preventDefault();
        var $t = $(this);
        var $url = $(this).attr('href');
        var $method = $(this).data('method');
        if (typeof $method === typeof undefined || $method === false) {
            $method = 'GET';
        }

        bootbox.confirm($t.data('confirm-text'), function (result) {
            if (result) {
                $.ajax({
                    url: $url,
                    type: $method,
                    success: function (data) {
                        if (data.success) {
                            $t.triggerHandler('success', data);

                            if (data.message != '') {
                                $('#alert-popup').notify({
                                    message: {
                                        text: data.message
                                    },
                                    type: 'success'
                                }).show();
                            }
                        }

                    },
                    error: function (data) {
                        $t.triggerHandler('error', data);

                        if (data.responseJSON.message) {
                            $('#alert-popup').notify({
                                message: {
                                    text: data.responseJSON.message
                                },
                                type: 'danger'
                            }).show();
                        }
                    }
                });
            }
        });
    });

    // link confirm dialog
    $('body').on('click', 'a.ajax', function (e) {
        e.preventDefault();
        var $t = $(this);
        var $url = $(this).attr('href');

        $.ajax({
            url: $url,
            success: function (data) {
                if (data.success) {
                    $t.triggerHandler('success', data);

                    if (data.message != '') {
                        $('#alert-popup').notify({
                            message: {
                                text: data.message
                            },
                            type: 'success'
                        }).show();
                    }
                }

            },
            error: function (data) {
                $t.triggerHandler('error', data);

                if (data.responseJSON.message) {
                    $('#alert-popup').notify({
                        message: {
                            text: data.responseJSON.message
                        },
                        type: 'danger'
                    }).show();
                }
            }
        });
    });

    // link confirm dialog
    $('body').on('click', 'a.confirm', function (e) {
        e.preventDefault();
        var $t = $(this);
        var $url = $(this).attr('href');

        bootbox.confirm($t.data('confirm-text'), function (result) {
            if (result) {
                location.href = $url;
            }
        });
    });

    // link modal
    $('body').on('click', 'a.remote-modal, a.bs-modal-remote', function (e) {
        e.preventDefault();
        var $url = $(this).attr('href');

        $.ajax({
            url: $url,
            success: function (data) {
                bootbox.dialog({
                    message: data,
                    buttons: {
                        main: {
                            label: "Schließen",
                            className: "btn-primary"
                        }
                    }
                });

                setTimeout(function () {
                    $('.bootbox.modal').scrollTop(0);
                }, 500);
            }
        });

    });

    // link modal
    $('body').on('click', 'a.remote-iframe', function (e) {
        e.preventDefault();
        var $url = $(this).attr('href');
        var $content = $('<iframe width="100%" height="600" frameborder="0">').attr('src', $url);

        bootbox.dialog({
            message: $content,
            size: 'large',
            buttons: {
                main: {
                    label: "Schließen",
                    className: "btn-primary"
                }
            }
        });
    });

    // tooltip for all
    $('[data-bs-title]').tooltip({
        container: 'body',
        trigger: 'hover',
        title: function () {
            return $(this).data('bs-title');
        }
    });

    // tooltip for all
    $('[data-tooltip]').tooltip({
        container: 'body',
        trigger: 'hover',
        title: function () {
            return $(this).data('tooltip');
        }
    });

    $('.bs-popover').popover();

    $(".bs-popover-keep").popover({trigger: "manual"})
        .on("mouseenter", function () {
            var _this = this;
            $(this).popover("show");
            $(".popover").on("mouseleave", function () {
                $(_this).popover('hide');
            });
        }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!$(".popover:hover").length) {
                $(_this).popover("hide");
            }
        }, 300);
    });
});
