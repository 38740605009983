$(function () {
    $('.tariffInfo .headline-collapse').click(function () {
        var element = $(this).next('tbody');
        element.slideToggle();
    });

    $(".flap-btn").parent('.flap').hover(
        function () {
            $(this).children('.collapse').collapse('show');
        }, function () {
            $(this).children('.collapse').collapse('hide');
        }
    );

    $(document).on('focus', '.bootstrap-date', function () {
        $(this).datepicker({
            language: 'de',
            format: 'dd.mm.yyyy',
            autoclose: true
        });
    });

    $('.info-modal').click(function () {
        var title = $(this).attr('data-title');
        var message = $(this).attr('data-content');

        bootbox.alert({
            title: title,
            message: message
        });

        return false;
    });

    $('.show-foot-notes').click(function () {
        var message = $('#foot-note-texts').html();

        bootbox.alert({
            message: message
        });

        return false;
    });

    $.notifyDefaults({
        type: 'danger',
        placement: {
            from: "top",
            align: "center"
        },
        animate: {
            enter: 'animated fadeInDown',
            exit: 'animated fadeOutUp'
        }
    });

    /*
    var $navbarMain = $('.navbar-main');
    var $origOffsetY = $navbarMain.offset().top;

    $navbarMain.on('fixed-navbar', function () {
        $('.logo-row').hide();

        $('body').css({'padding-top': '145px'});
        $navbarMain.addClass('navbar-fixed-top');
    });

    $navbarMain.on('static-navbar', function () {
        $('.logo-row').show();

        $('body').css({'padding-top': '0'});
        $navbarMain.removeClass('navbar-fixed-top');
    });

    var $navbarMainFunction = function () {
        if ($(window).scrollTop() >= $origOffsetY) {
            if (!$navbarMain.hasClass('navbar-fixed-top')) {
                $navbarMain.trigger('fixed-navbar');
            }

        } else {
            if ($navbarMain.hasClass('navbar-fixed-top')) {
                $navbarMain.trigger('static-navbar');
            }
        }
    }

    $(document).on('scroll', $navbarMainFunction);
    $navbarMainFunction;
    */
});