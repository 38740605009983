$(function () {
    // open link in new tab/window
    $('body').on('click', 'a.newtab', function (e) {
        e.preventDefault();

        window.open($(this).attr('href'));
    });

    // max selections for html select input
    $('select[multiple][max]').change(function (e) {
        if ($(this).val().length > $(this).attr('max')) {
            $(this).val($(this).data('selections'));
        } else {
            $(this).data('selections', $(this).val());
        }
    });
});