function assignModalEvents($modal, $link) {
    $modal.on('hidden.bs.modal', function (event) {
        $link.trigger('bs.modal.remove', event);
        $modal.remove();
    });
}

function assignModalContentEvents($modal, $link) {
    var $modalContent = $modal.find('.modal-content');
    var $form = $modalContent.find('form');

    $form.find('[type=submit]').on('click', function () {
        var $formData = $form.serializeArray();

        $formData.push({
            name: this.name,
            value: this.value
        });

        var $action = $form.attr('action');
        var $params = $.param($formData);

        $.post($action, $params)
            .done(function (result) {
                $link.trigger('bs.modal.form.success', result);

                $modal.modal('hide');
            })
            .fail(function (result) {
                $link.trigger('bs.modal.form.error', result);

                var $newHtml = result.responseJSON.form;
                var $newModal = $($newHtml);
                var $newContent = $newModal.find('.modal-content');

                $modalContent.replaceWith($newContent);

                assignModalContentEvents($modal, $link);
            });
    });

    $form.on('submit', function (e) {
        e.preventDefault();
    });
}

$(document).ready(function () {
    var $modalfunction = function (event) {
        event.preventDefault();

        var $link = $(this);
        var $href = $link.attr('href');

        $link.trigger('bs.modal.loading');

        $.ajax({
            url: $href,
            success: function (data) {
                var $exist = $('.bootstrap-modal');

                var $modal = $(data);
                $modal.addClass('bootstrap-modal');

                $link.trigger('bs.modal.finished');

                if ($exist.length > 0) {
                    $exist.find('.modal-content').html($modal.find('.modal-content').html());

                    assignModalEvents($exist, $link);
                    assignModalContentEvents($exist, $link);
                } else {
                    $('body').append($modal);

                    assignModalEvents($modal, $link);
                    assignModalContentEvents($modal, $link);

                    $modal.modal('show');
                }

            },
            error: function (jqXHR, textStatus, errorThrown) {
                $link.trigger('bs.modal.aborted');
            }
        });

        return false;
    }

    $(document).on('click', 'a.bs-modal-form, a.bs-modal', $modalfunction);
});