$( document ).ready(function() {

    let simCardCount = 0;

    let simDiv = $('#contract_details_form_sims_simCards');
    let addSimBtn = simDiv.find('.btn.btn-primary.btn-sm');

    let settings =  {
        'maxSimCards': 3
    };

    /**
     * Hide add sim card button.
     */
    function hideAddSimCardBtn() {
        let addSimBtn = simDiv.find('.btn.btn-primary.btn-sm');
        addSimBtn.hide();
    }

    /**
     * Display add sim card button.
     */
    function showAddSimCardBtn() {
        let addSimBtn = simDiv.find('.btn.btn-primary.btn-sm');
        addSimBtn.show();
    }

    /**
     * Display or hide the add sim card button based on the current sim card count.
     * @param count
     */
    function handleDisplayAddButton(count) {
        if(count === settings.maxSimCards) {
            hideAddSimCardBtn();
        } else {
            showAddSimCardBtn();
        }
    }

    // event when add sim card btn is clicked
    addSimBtn.click(function() {
        simCardCount++;
        handleDisplayAddButton(simCardCount);
    });

    // event when remove sim card btn is clicked
    $(document).on('click','.panel-footer a',function(){
        simCardCount--;
        handleDisplayAddButton(simCardCount);
    })
});