(function ($) {
    $.fn.extend({
        hasEvent: function (type) {
            var data = jQuery._data(this[0], "events");

            if (typeof data[type] !== "undefined" && data[type])
                return true;
            else
                return false;
        }
    });
})(jQuery);