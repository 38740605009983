$(function () {
    $(document).on('bs.modal.loading', '.bs-modal-form, .bs-modal', function (e) {
        $('body').loading({
            message: '<span class="loader"></span>'
        });
    });
    $(document).on('bs.modal.finished', '.bs-modal-form, .bs-modal', function (e) {
        $('body').loading('stop');
    });
    $(document).on('bs.modal.aborted', '.bs-modal-form, .bs-modal', function (e) {
        $('body').loading('stop');
    });
});
